import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  RadioGroup,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { Text, Button, Input, Flex, Box, Radio } from "@chakra-ui/react";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import * as API from "@lib/hooks/api";
import { AmplitudeEvents, ReminderType } from "@lib/types";
import ImageNext from "next/image";

import StarIcon from "public/images/Star.svg";
import ClockIcon from "public/images/Clock.svg";
import { logEvent } from "@amplitude/analytics-browser";

export const SetReminderModal = ({
  userId,
  announcementId,
  onClose,
}: {
  userId: number;
  announcementId: number;
  onClose: () => void;
}) => {
  const [type, setType] = useState(ReminderType.Time);
  const [time, setTime] = useState(new Date());
  const [gwei, setGwei] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    await API.post("/api/reminder", {
      userId,
      type,
      trigger: type === ReminderType.Time ? time : gwei,
      announcementId,
    });
    logEvent(AmplitudeEvents.ReminderCreated);
    setIsSubmitting(false);
    onClose();
  };

  return (
    <>
      <Modal isOpen={true} onClose={onClose} isCentered>
        <ModalOverlay bg="rgba(0, 0, 0, 0.79)" />
        <ModalContent
          bg="#232F3D"
          w={{ base: "90%", md: "540px", lg: "679px" }}
          maxW={{ base: "90%", md: "540px", lg: "679px" }}
          boxShadow="0px 0px 1px rgba(0, 0, 0, 0.32)"
          borderRadius={"6px"}
          p={{ base: "20px", md: "24px", lg: "32px" }}
        >
          <ModalHeader
            color="#fff"
            letterSpacing={"-0.014em"}
            fontSize={{ base: "18px" }}
            lineHeight={{ base: "24px" }}
            fontWeight="600"
            p="0"
            mb="15px"
          >
            Set Reminder
          </ModalHeader>
          <ModalCloseButton color="#fff" right="25px" top="25px" />

          <ModalBody p="0">
            <Text
              color="#C2C9CE"
              letterSpacing={"-0.011em"}
              fontSize={{ base: "16px" }}
              lineHeight={{ base: "24px" }}
              fontWeight="400"
              mb="35px"
              pr="30px"
            >
              Can’t take action now, but afriad you’ll forget? Set a reminder!
              You can set a reminder to a certain date and time, or a reminder
              to act when gwei is below a certain threshold.
            </Text>

            <Flex direction="column">
              <RadioGroup
                value={type}
                onChange={(value) => setType(value as any)}
              >
                <Box
                  border="1px solid #A6B5BD"
                  p="16px 14px"
                  mb="10px"
                  borderRadius={6}
                >
                  <Radio
                    value={ReminderType.Time}
                    onChange={console.log}
                    alignItems="flex-start"
                  >
                    <Flex alignItems="flex-start" mt="-4px">
                      <ImageNext alt="" src={ClockIcon} />{" "}
                      <Box ml="18px">
                        <Text
                          color="#fff"
                          letterSpacing={"-0.006em"}
                          fontSize={{ base: "12px" }}
                          lineHeight={{ base: "22px" }}
                          fontWeight="600"
                        >
                          Date &amp; Time
                        </Text>
                        <Text
                          color="rgba(255, 255, 255, 0.5)"
                          letterSpacing={"-0.006em"}
                          fontSize={{ base: "12px" }}
                          lineHeight={{ base: "22px" }}
                          fontWeight="400"
                        >
                          Get a reminder on a certain date / time.
                        </Text>
                      </Box>
                    </Flex>
                  </Radio>
                </Box>

                <Box
                  border="1px solid #A6B5BD"
                  p="16px 14px"
                  mb="35px"
                  borderRadius={6}
                >
                  <Radio
                    value={ReminderType.Gwei}
                    onChange={console.log}
                    alignItems="flex-start"
                  >
                    <Flex alignItems="flex-start" mt="-4px">
                      <ImageNext alt="" src={StarIcon} />{" "}
                      <Box ml="18px">
                        <Text
                          color="#fff"
                          letterSpacing={"-0.006em"}
                          fontSize={{ base: "12px" }}
                          lineHeight={{ base: "22px" }}
                          fontWeight="600"
                        >
                          Gwei Based
                        </Text>
                        <Text
                          color="rgba(255, 255, 255, 0.5)"
                          letterSpacing={"-0.006em"}
                          fontSize={{ base: "12px" }}
                          lineHeight={{ base: "22px" }}
                          fontWeight="400"
                        >
                          Get a reminder when gwei dips below a threhold.
                        </Text>
                      </Box>
                    </Flex>
                  </Radio>
                </Box>
              </RadioGroup>
              {type === ReminderType.Gwei ? (
                <Box>
                  <Text
                    color="#fff"
                    letterSpacing={"-0.006em"}
                    fontSize={{ base: "14px" }}
                    lineHeight={{ base: "24px" }}
                    fontWeight="700"
                  >
                    Gwei Threshold
                  </Text>
                  <Text
                    color="#C2C9CE"
                    letterSpacing={"-0.011em"}
                    fontSize={{ base: "12px" }}
                    lineHeight={{ base: "16px" }}
                    fontWeight="400"
                    mb="6px"
                  >
                    At what gwei do you want to be notified?
                  </Text>

                  <InputGroup
                    maxW="280px"
                    borderRadius={"6px"}
                    color="#fff"
                    letterSpacing={"-0.006em"}
                    fontSize={{ base: "14px" }}
                    lineHeight={{ base: "24px" }}
                    fontWeight="400"
                  >
                    <Input
                      color="#fff"
                      letterSpacing={"-0.006em"}
                      fontSize={{ base: "14px" }}
                      lineHeight={{ base: "24px" }}
                      fontWeight="400"
                      border="1px solid #6E767A"
                      type="number"
                      value={gwei}
                      onChange={(event) => setGwei(Number(event.target.value))}
                    />
                    <InputRightElement>
                      <Text
                        color="#fff"
                        letterSpacing={"-0.006em"}
                        fontSize={{ base: "14px" }}
                        lineHeight={{ base: "24px" }}
                        fontWeight="700"
                        mr="4px"
                      >
                        gwei
                      </Text>
                    </InputRightElement>
                  </InputGroup>
                </Box>
              ) : (
                <Box>
                  <Text
                    color="#fff"
                    letterSpacing={"-0.006em"}
                    fontSize={{ base: "14px" }}
                    lineHeight={{ base: "24px" }}
                    fontWeight="700"
                  >
                    Date Time
                  </Text>
                  <Text
                    color="#9AA6AC"
                    letterSpacing={"-0.011em"}
                    fontSize={{ base: "12px" }}
                    lineHeight={{ base: "16px" }}
                    fontWeight="400"
                    mb="6px"
                  >
                    At what gwei do you want to be notified?
                  </Text>

                  <DatePicker
                    showTimeSelect
                    dateFormat="Pp"
                    selected={time}
                    onSelect={(date) => setTime(date)} //when day is clicked
                    onChange={(date) => date && setTime(date)} //only when value has changed
                  />
                </Box>
              )}
            </Flex>
          </ModalBody>

          <ModalFooter p="0" mt="35px">
            <Button
              onClick={onClose}
              bg="#323F4E"
              colorScheme="#323F4E"
              borderRadius={"6px"}
              p="4px 12px"
              color="#ffff"
              letterSpacing={"-0.006em"}
              fontSize={{ base: "14px" }}
              lineHeight={{ base: "24px" }}
              fontWeight="600"
              h="32px"
              mr="12px"
              _hover={{
                opacity: 0.7,
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              isLoading={isSubmitting}
              bg="#4094F7"
              colorScheme="#4094F7"
              borderRadius={"6px"}
              p="4px 12px"
              color="#F6F8F9"
              letterSpacing={"-0.006em"}
              fontSize={{ base: "14px" }}
              lineHeight={{ base: "24px" }}
              fontWeight="600"
              h="32px"
              _hover={{
                opacity: 0.7,
              }}
            >
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
