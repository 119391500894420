import { ArrowForwardIcon, CheckIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Switch, Text } from "@chakra-ui/react";
import { useUpgrade } from "@lib/components/upgrade";
import useAPI from "@lib/hooks/api";
import Image from "next/image";
import { useRouter } from "next/router";
import { SettingsData } from "pages/settings";
import ThreeDotsIcon from "public/images/three-dots.svg";

interface IProps {
  verificationFilter: boolean;
  setVerficationFilter: Function;
  smartFilter: boolean;
  setSmartFilter: Function;
  onlyTimeSensitive: boolean;
  setOnlyTimeSensitive: Function;
  showTrackModal: boolean;
  setShowTrackModal: Function;
}

const Sidebar = ({
  smartFilter,
  verificationFilter,
  setSmartFilter,
  setVerficationFilter,
  onlyTimeSensitive,
  setOnlyTimeSensitive,
  showTrackModal,
  setShowTrackModal,
}: IProps) => {
  const { upgrade } = useUpgrade();
  const router = useRouter();
  const { data } = useAPI<SettingsData>("/api/settings");

  const navigate = (url: string) => {
    router.push(url);
  };

  return (
    <Flex width={"30%"} direction="column">
      <Box>
        <Text
          textTransform={"uppercase"}
          fontFamily={"Space Mono"}
          fontWeight="400"
          fontSize={"16px"}
          color={"#9AA6AC"}
        >
          Filters
        </Text>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          bg="#232F3D"
          p="15px"
          border="1px solid #17202A"
          boxSizing="border-box"
          borderRadius={"6px"}
          mb={"10px"}
          mt={"10px"}
          cursor={"pointer"}
        >
          <Text
            fontSize={"14px"}
            lineHeight="24px"
            fontWeight={"400"}
            letterSpacing="-0.006em"
            color="#9AA6AC"
            mr="10px"
            fontFamily={"Space Mono"}
          >
            ✅ OpenSea Verified Filter
          </Text>
          <Switch
            isChecked={verificationFilter}
            alignItems="center"
            display="flex"
            onChange={() => setVerficationFilter(!verificationFilter)}
          />
        </Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          bg="#232F3D"
          p="15px"
          border="1px solid #17202A"
          boxSizing="border-box"
          borderRadius={"6px"}
          mb={"10px"}
          cursor={"pointer"}
        >
          <Text
            fontSize={"14px"}
            lineHeight="24px"
            fontWeight={"400"}
            letterSpacing="-0.006em"
            color="#9AA6AC"
            mr="10px"
            fontFamily={"Space Mono"}
          >
            ✨ Smart Filter (Beta)
          </Text>
          <Switch
            isChecked={smartFilter}
            alignItems="center"
            display="flex"
            onChange={() => setSmartFilter(!smartFilter)}
          />
        </Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          bg="#232F3D"
          p="15px"
          border="1px solid #17202A"
          boxSizing="border-box"
          borderRadius={"6px"}
          cursor={"pointer"}
        >
          <Text
            fontSize={"14px"}
            lineHeight="24px"
            fontWeight={"400"}
            letterSpacing="-0.006em"
            color="#9AA6AC"
            mr="10px"
            fontFamily={"Space Mono"}
          >
            📅 Mentions Date
          </Text>
          <Switch
            isChecked={onlyTimeSensitive}
            onChange={() => setOnlyTimeSensitive(!onlyTimeSensitive)}
            alignItems="center"
            display="flex"
          />
        </Box>
      </Box>

      <Box mt={"30px"}>
        <Text
          textTransform={"uppercase"}
          fontFamily={"Space Mono"}
          fontWeight="400"
          fontSize={"14px"}
          color={"#9AA6AC"}
        >
          Settings
        </Text>

        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          bg="#232F3D"
          p="15px"
          border="1px solid #17202A"
          boxSizing="border-box"
          borderRadius={"6px"}
          mt={"10px"}
          mb={"10px"}
          onClick={upgrade(() => setShowTrackModal(!showTrackModal))}
          cursor={"pointer"}
        >
          <Text
            fontSize={"14px"}
            lineHeight="24px"
            fontWeight={"400"}
            letterSpacing="-0.006em"
            color="#9AA6AC"
            mr="10px"
            fontFamily={"Space Mono"}
          >
            👀 Track Additional Project
          </Text>

          <ArrowForwardIcon color="#9AA6AC" />
        </Box>

        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          bg="#232F3D"
          p="15px"
          border="1px solid #17202A"
          boxSizing="border-box"
          borderRadius={"6px"}
          mt={"10px"}
          mb={"10px"}
          cursor={"pointer"}
          onClick={() => navigate("/settings")}
        >
          <Text
            fontSize={"14px"}
            lineHeight="24px"
            fontWeight={"400"}
            letterSpacing="-0.006em"
            color="#9AA6AC"
            mr="10px"
            fontFamily={"Space Mono"}
          >
            🔔 Manage Notifications
          </Text>

          <ArrowForwardIcon color="#9AA6AC" />
        </Box>
      </Box>

      <Box
        bg="#232F3D"
        p="15px"
        border="1px solid #17202A"
        boxSizing="border-box"
        borderRadius={"6px"}
      >
        <Flex
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Text
            textTransform={"uppercase"}
            fontFamily={"Space Mono"}
            fontWeight="400"
            fontSize={"14px"}
            color={"#9AA6AC"}
          >
            Wallets
          </Text>
          <Button
            leftIcon={<ArrowForwardIcon />}
            bg={"#1A2430"}
            color={"#fff"}
            fontWeight={"400"}
            fontFamily={"Space Mono"}
            size="md"
            fontSize={"14px"}
            borderRadius={"50px"}
            _hover={{ bg: "#1A2430" }}
            onClick={() => navigate("/settings")}
          >
            ADD WALLET
          </Button>
        </Flex>

        <Box display={"flex"} flexDirection={"column"} gap={"15px"} mt={"15px"}>
          {data?.user?.trackedWallets?.map((wallet: string) => (
            <Flex
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              key={wallet}
            >
              <Text fontFamily={"Inter"} color={"#FFFFFFB2"}>
                {`${wallet.slice(0, 8)}...${wallet.slice(-8)}`}
              </Text>
              <Flex gap={"10px"} alignItems="center">
                <CheckIcon color={"#74D89C"} />
                <Image src={ThreeDotsIcon} alt="three dots" />
              </Flex>
            </Flex>
          ))}
        </Box>
      </Box>

      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        bg="#232F3D"
        p="15px"
        border="1px solid #17202A"
        boxSizing="border-box"
        borderRadius={"6px"}
        mb={"10px"}
        mt={"10px"}
        cursor={"pointer"}
        onClick={() => navigate("/settings")}
      >
        <Text
          fontSize={"14px"}
          lineHeight="24px"
          fontWeight={"400"}
          letterSpacing="-0.006em"
          color="#9AA6AC"
          mr="10px"
          fontFamily={"Space Mono"}
        >
          ⚙️ View all Settings
        </Text>
        <ArrowForwardIcon color="#9AA6AC" />
      </Box>
    </Flex>
  );
};

export default Sidebar;
