import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  Button,
  Input,
  Link,
} from "@chakra-ui/react";
import Image from "next/image";
import { useSWRConfig } from "swr";
import * as API from "@lib/hooks/api";
import { useState } from "react";

import EyeIcon from "public/images/eye.svg";
import { logEvent } from "@amplitude/analytics-browser";
import { AmplitudeEvents } from "@lib/types";

export const TrackProjectModal = ({
  userId,
  onClose,
}: {
  userId: number;
  onClose: any;
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { mutate } = useSWRConfig();
  const [twitterUsername, setTwitterUsername] = useState("");

  const handleSubmit = async () => {
    setIsSubmitting(true);
    await API.post("/api/track/project", {
      userId,
      twitterUsername,
    });
    logEvent(AmplitudeEvents.ManuallyTrackedProject);
    mutate(`/api/track/project?id=${userId}`);
    setIsSubmitting(false);
    onClose();
  };

  return (
    <>
      <Modal isOpen={true} onClose={onClose} isCentered>
        <ModalOverlay bg="rgba(0, 0, 0, 0.79)" />
        <ModalContent
          bg="#232F3D"
          w={{ base: "100%", md: "540px", lg: "679px" }}
          maxW={{ base: "100%", md: "540px", lg: "679px" }}
          boxShadow="0px 0px 1px rgba(0, 0, 0, 0.32)"
          borderRadius={"6px"}
          p={{ base: "20px", md: "24px", lg: "32px" }}
        >
          <ModalHeader
            color="#fff"
            letterSpacing={"-0.014em"}
            fontSize={{ base: "18px" }}
            lineHeight={{ base: "24px" }}
            fontWeight="600"
            p="0"
            mb="15px"
          >
            <Image alt="" src={EyeIcon} width="14px" height="14px" /> Track
            Additional Projects
          </ModalHeader>
          <ModalCloseButton
            color="#fff"
            right="25px"
            top="25px"
            _active={{
              outline: "none",
            }}
            _focus={{
              outline: "none",
            }}
          />

          <ModalBody p="0">
            <Text
              color="#C2C9CE"
              letterSpacing={"-0.011em"}
              fontSize={{ base: "16px" }}
              lineHeight={{ base: "24px" }}
              fontWeight="400"
              mb="15px"
            >
              Want to track additional projects that have not launched yet, or
              that you do not own yet? Just enter the link to it’s twitter
              profile below.
            </Text>
            <Text
              color="#C2C9CE"
              letterSpacing={"-0.011em"}
              fontSize={{ base: "16px" }}
              lineHeight={{ base: "24px" }}
              fontWeight="400"
              mb="35px"
            >
              To manage your tracked projects, go to{" "}
              <Link href="#" color="#4094F7">
                NFT Settings
              </Link>
            </Text>

            <Text
              color="#fff"
              letterSpacing={"-0.006em"}
              fontSize={{ base: "14px" }}
              lineHeight={{ base: "24px" }}
              fontWeight="700"
              mb="6px"
            >
              {" "}
              Twitter Profile
            </Text>
            <Input
              placeholder="handle"
              onChange={(event) => setTwitterUsername(event.target.value)}
              maxW="280px"
              bg="#212C38"
              border="1px solid #6E767A"
              borderRadius={"6px"}
              color="#A1A1A1"
              letterSpacing={"-0.006em"}
              fontSize={{ base: "14px" }}
              lineHeight={{ base: "24px" }}
              fontWeight="400"
            />
          </ModalBody>
          <ModalFooter p="0" mt="35px">
            <Button
              variant="ghost"
              onClick={onClose}
              bg="#323F4E"
              colorScheme="#323F4E"
              borderRadius={"6px"}
              p="4px 12px"
              color="#ffff"
              letterSpacing={"-0.006em"}
              fontSize={{ base: "14px" }}
              lineHeight={{ base: "24px" }}
              fontWeight="600"
              h="32px"
              mr="12px"
              _hover={{
                opacity: 0.7,
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleSubmit()}
              bg="#4094F7"
              colorScheme="#4094F7"
              borderRadius={"6px"}
              p="4px 12px"
              color="#F6F8F9"
              letterSpacing={"-0.006em"}
              fontSize={{ base: "14px" }}
              lineHeight={{ base: "24px" }}
              fontWeight="600"
              h="32px"
              _hover={{
                opacity: 0.7,
              }}
              isLoading={isSubmitting}
            >
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
