import dayjs from "dayjs";

export const formatDate = (
  selectedDate: Date,
  format: string = "MM/DD - hh:mm a"
) => {
  if (!selectedDate) return;
  const date = dayjs(selectedDate).format(format);
  return date;
};

export const getDateDifference = (firstDate: any, secondDate: any) => {
  if (!firstDate || !secondDate) return 100;
  const formatDateOne = dayjs(firstDate).format("YYYY-MM-DD");
  const date1 = dayjs(formatDateOne);
  const formatDateTwo = dayjs(secondDate).format("YYYY-MM-DD");
  const date2 = dayjs(formatDateTwo);

  let res = date1.diff(formatDateTwo, "d");
  if (res < 0) {
    res = date2.diff(formatDateOne, "d");
  }
  return res;
};
