import AirdropIcon from "public/images/Airdrop.svg";
import ClaimIcon from "public/images/Claim.svg";
import SnapshotIcon from "public/images/Snapshot.svg";
import ExpirationIcon from "public/images/Expiration.svg";
import PreSaleIcon from "public/images/Pre-Sale.svg";
import PublicMintIcon from "public/images/Public-Mint.svg";
import MerchandiseIcon from "public/images/Merchandise.svg";
import GiveawayIcon from "public/images/Giveaway.svg";

const feedTypes = {
  Airdrop: {
    icon: AirdropIcon,
    color: "#7CAAEF",
  },
  Claim: {
    icon: ClaimIcon,
    color: "#ECB684",
  },
  Snapshot: {
    icon: SnapshotIcon,
    color: "#84EC88",
  },
  Expiration: {
    icon: ExpirationIcon,
    color: "#FA8F8F",
  },
  "Pre-Sale": {
    icon: PreSaleIcon,
    color: "#93FFE5",
  },
  "Public Sale": {
    icon: PublicMintIcon,
    color: "#82F26F",
  },
  "Mint": {
    icon: PublicMintIcon,
    color: "#82F26F",
  },
  "Merch Drop": {
    icon: MerchandiseIcon,
    color: "#F8ADE2",
  },
  Giveaway: {
    icon: GiveawayIcon,
    color: "#FF76C0",
  },
};

export default feedTypes;
