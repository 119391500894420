import Page from "@lib/components/page";
import Image from "next/image";
import React, { useState } from "react";
import {
  Button,
  Flex,
  Switch,
  Text,
  Box,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
} from "@chakra-ui/react";
import { TrackProjectModal } from "@lib/components/track-project-modal";
import Content, { AnnouncementsData } from "@lib/components/announcement-cards";
import { useUserContext } from "@lib/hooks/user";
import { useUpgrade } from "@lib/components/upgrade";
import EyeIcon from "public/images/eye.svg";
import { useRouter } from "next/router";

import config from "@src/config";
import DashboardAnalysis, {
  AnalysisResponseData,
} from "@lib/components/dashboard-analysis";
import { ChevronUpIcon } from "@chakra-ui/icons";
import Sidebar from "@lib/components/sidebar";
import { getDateDifference } from "utils/formatDate";

const AnnouncementContainer = ({
  data,
  analysisData,
  analysisLoading,
}: {
  data?: AnnouncementsData;
  analysisData?: AnalysisResponseData;
  analysisLoading: string;
}) => {
  const { user } = useUserContext();
  const router = useRouter();
  const { walletId } = router.query;

  const [onlyTimeSensitive, setOnlyTimeSensitive] = useState(false);
  const [smartFilter, setSmartFilter] = useState(false);
  const [verificationFilter, setVerficationFilter] = useState(false);
  const [showCompleted, setShowCompleted] = useState(false);
  const [showTrackModal, setShowTrackModal] = useState(false);
  const { renderModal, upgrade } = useUpgrade();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const wallet = config.whitelistedWallet.find(
    (wallet) => wallet.walletAddress === walletId
  );

  const totalPending = analysisData?.data?.filter(
    (p) =>
      (p.twitterUsername.status === "error" &&
        p.twitterUsername.value.toLowerCase() !== "not found") ||
      (p.twitterUsername.status === "success" &&
        getDateDifference(new Date(), p.lastChecked) > 0)
  );

  console.log("pending", totalPending, analysisLoading, data);

  if (!data || (data.analyzing && !data.announcements.length)) {
    return (
      <Page>
        <Flex gap={"100px"}>
          <Sidebar
            verificationFilter={verificationFilter}
            setVerficationFilter={setVerficationFilter}
            smartFilter={smartFilter}
            setSmartFilter={setSmartFilter}
            onlyTimeSensitive={onlyTimeSensitive}
            setOnlyTimeSensitive={setOnlyTimeSensitive}
            showTrackModal={showTrackModal}
            setShowTrackModal={setShowTrackModal}
          />
          <Box flexGrow={1}>
            <Box
              bg="#232F3D"
              borderRadius={"6px"}
              padding={"30px 50px"}
              my={{ base: "10px", md: "35px" }}
            >
              <Text
                fontSize={{ base: "16px", lg: "20px" }}
                lineHeight={"24px"}
                fontWeight="600"
                letterSpacing={"-0.006em"}
                color="#fff"
                mb="22px"
                display="flex"
                alignItems="center"
                justifyContent={"center"}
              >
                Scottie is analyzing...
              </Text>
            </Box>

            <Box
              position={"fixed"}
              onClick={onOpen}
              left={{ base: "10px", lg: "80px" }}
              bottom="0"
              cursor={"pointer"}
              border={"1px solid #656560"}
              background={
                totalPending && totalPending.length === 0
                  ? "#337429"
                  : "#656560"
              }
              p={"11px 20px"}
              borderRadius="10px 10px 0px 0px"
              display={"flex"}
              justifyContent={"center"}
              fontSize="14px"
            >
              <Box>
                <Text fontSize={"14px"} fontFamily={"Space Mono"}>
                  {totalPending && totalPending.length > 0
                    ? `Fetching Updates (${
                        totalPending ? totalPending.length : 0
                      }`
                    : "Analysis complete"}
                </Text>
              </Box>

              <Modal
                isOpen={isOpen}
                onClose={onClose}
                size={"4xl"}
                motionPreset="slideInRight"
                variant="wide"
              >
                <ModalOverlay />
                <ModalContent>
                  <ModalBody>
                    <DashboardAnalysis
                      analysisData={analysisData}
                      onClose={onClose}
                      loading={analysisLoading}
                    />
                  </ModalBody>
                </ModalContent>
              </Modal>
            </Box>
          </Box>
        </Flex>
      </Page>
    );
  }

  return (
    <Page>
      <Flex direction="row" gap="100px">
        <Sidebar
          verificationFilter={verificationFilter}
          setVerficationFilter={setVerficationFilter}
          smartFilter={smartFilter}
          setSmartFilter={setSmartFilter}
          onlyTimeSensitive={onlyTimeSensitive}
          setOnlyTimeSensitive={setOnlyTimeSensitive}
          showTrackModal={showTrackModal}
          setShowTrackModal={setShowTrackModal}
        />

        <Flex
          direction="column"
          pb={{ base: "106px", lg: "0" }}
          position="relative"
          width={"70%"}
        >
          <Box
            display={{ base: "flex", md: "none" }}
            alignItems="center"
            p="25px 22px"
            borderBottom={"1px solid #fff"}
          >
            <Text
              fontSize={"27px"}
              lineHeight="33px"
              fontWeight={700}
              color="#fff"
            >
              Updates
            </Text>
            <Button
              ml="auto"
              color="#414141"
              fontSize={"15px"}
              lineHeight="18px"
              fontWeight={400}
              bg="#FFFFFF"
              borderRadius={5}
              p={"9px 10px"}
              _disabled={{
                bg: "rgba(255, 255, 255, 0.52)",
              }}
              onClick={upgrade(() => setShowTrackModal(!showTrackModal))}
            >
              + Add Project
            </Button>
          </Box>

          {wallet && (
            <Box
              bg="linear-gradient(100.02deg, #E7D6AC 6.19%, #E7C5BA 86.56%)"
              borderRadius={{ base: 0, md: "7px" }}
              p={{ base: "16px 20px", md: "33px 41px" }}
              display={{ base: "inline-block", md: "flex" }}
              alignItems="flex-start"
              mb="44px"
              w={{ base: "100%", lg: "calc(100% + 90px)" }}
              ml={{ base: 0, lg: "-45px" }}
              mt={{ base: "20px", lg: 0 }}
            >
              <Box
                w={{ base: "65px", md: "101px" }}
                h={{ base: "65px", md: "101px" }}
                borderRadius={"50%"}
                overflow="hidden"
                mr="49px"
                mb={{ base: "16px", md: 0 }}
                position="relative"
              >
                <Image alt="" src={wallet.ownerProfilePicture} layout="fill" />
              </Box>
              <Box w={{ base: "100%", md: "calc(100% - 150px)" }}>
                <Text
                  fontSize={"22px"}
                  lineHeight="27px"
                  fontWeight={700}
                  fontFamily="Inter"
                  color="#170B3B"
                >
                  This is a preview of {wallet.Name}’s wallet{" "}
                </Text>
                <Text
                  fontSize={"16px"}
                  lineHeight="20px"
                  fontWeight={500}
                  fontFamily="Inter"
                  color="rgba(23, 32, 42, 0.7)"
                  m="7px 0 16px"
                >
                  Sign up to see updates for your NFTs and unlock all features.
                </Text>

                <Button
                  variant="outline"
                  colorScheme={"#454545"}
                  fontSize={"12px"}
                  lineHeight="18px"
                  fontWeight={700}
                  fontFamily="Space Mono"
                  color="#252525"
                >
                  {`Sign Up ->`}{" "}
                </Button>
              </Box>
            </Box>
          )}

          {user.id && showTrackModal && (
            <TrackProjectModal
              userId={user.id}
              onClose={() => setShowTrackModal(false)}
            />
          )}
          {renderModal()}
          <Content
            data={data}
            onlyTimeSensitive={onlyTimeSensitive}
            smartFilter={smartFilter}
            showCompleted={showCompleted}
            verificationFilter={verificationFilter}
            setShowCompleted={setShowCompleted}
          />

          <Box
            position={"fixed"}
            onClick={onOpen}
            left={{ base: "10px", lg: "80px" }}
            bottom="0"
            cursor={"pointer"}
            border={"1px solid #656560"}
            background={
              totalPending && totalPending.length > 0 ? "#656560" : "#337429"
            }
            p={"11px 20px"}
            borderRadius="10px 10px 0px 0px"
            display={"flex"}
            justifyContent={"center"}
            fontSize="14px"
          >
            <Box>
              <Text fontSize={"14px"} fontFamily={"Space Mono"} width={"250px"}>
                {totalPending &&
                totalPending.length > 0 &&
                analysisLoading === "analysis"
                  ? `Fetching Updates (${
                      totalPending ? totalPending.length : 0
                    }`
                  : "Analysis complete"}
              </Text>
            </Box>

            <Modal
              isOpen={isOpen}
              onClose={onClose}
              size={"4xl"}
              motionPreset="slideInRight"
              variant="wide"
            >
              <ModalOverlay />
              <ModalContent>
                <ModalBody>
                  <DashboardAnalysis
                    analysisData={analysisData}
                    onClose={onClose}
                    loading={analysisLoading}
                  />
                </ModalBody>
              </ModalContent>
            </Modal>
          </Box>
        </Flex>
      </Flex>
    </Page>
  );
};

export default AnnouncementContainer;
