import ImageNext from "next/image";
import moment from "moment";
import {
  Box,
  Button,
  Flex,
  Image,
  Text,
  Spacer,
  Badge,
  Link,
} from "@chakra-ui/react";
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { SetReminderModal } from "@lib/components/set-reminder";
import { User } from "@lib/hooks/user";
import { useRouter } from "next/router";
import markComplete from "pages/api/announcements/mark-complete";
import * as API from "@lib/hooks/api";
import { useSWRConfig } from "swr";
import { useUserContext } from "@lib/hooks/user";
import { useUpgrade } from "./upgrade";

import CalendarIcon from "public/images/calendar.svg";
import SourceIcon from "public/images/arrow-right-circle.svg";
import BellIcon from "public/images/bell.svg";
import CheckedIcon from "public/images/check-circle.svg";
import UserIcon from "public/images/user.svg";

import feeds from "utils/feeds";
import { getDateDifference } from "utils/formatDate";
import config from "@src/config";

type Announcement = {
  id: number;
  twitterUsername: string;
  content: string;
  tweetId: string;
  announcedAt: string;
  type: string;
  isCompleted: boolean;
  isTimeSensitive: boolean;
  isTypeVerified: boolean;
};

export type AnnouncementsData = {
  analyzing: boolean;
  projects: Array<{
    slug?: string;
    twitterUsername: string;
    name: string;
    imageUrl: string;
    verified?: string;
  }>;
  announcements: Array<Announcement>;
};

enum CardState {
  Collapsed = "Collapsed",
  Expanded = "Expanded",
}

const AnnouncementCard = ({
  userId,
  announcements,
  project,
}: {
  userId: number | null;
  announcements: Announcement[];
  project: any;
}) => {
  const feedTypes: any = feeds;
  const [cardState, setCardState] = useState(CardState.Collapsed);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { imageUrl, name } = project;
  const { upgrade, renderModal } = useUpgrade();
  const { mutate } = useSWRConfig();
  const [showReminderModal, setShowReminderModal] = useState(false);
  const latestAnnouncement = announcements[0];

  const onViewSource = () => {
    const url =
      announcements.length === 1
        ? `https://twitter.com/${latestAnnouncement.twitterUsername}/status/${latestAnnouncement.tweetId}`
        : `https://twitter.com/${latestAnnouncement.twitterUsername}`;
    window.open(url);
  };

  const markComplete = async () => {
    setIsSubmitting(true);
    const announcementIds = announcements.map((a) => a.id);
    await API.post("/api/announcements/mark-complete", {
      announcementIds,
      isCompleted: true,
    });
    mutate(`/api/announcements`);
    setIsSubmitting(false);
    return;
  };

  if (cardState === CardState.Expanded) {
    return (
      <Box
        marginTop={{ base: "10px", sm: "33px" }}
        padding={{ base: "10px 0px 10px 20px" }}
        color="#fff"
        borderRadius={{ base: 0, md: "6px" }}
        borderLeft="4px solid #232F3D"
      >
        <Button
          variant="link"
          fontWeight={400}
          fontSize={{ base: "12px", lg: "14px" }}
          letterSpacing={"-0.006em"}
          lineHeight={{ base: "20px", lg: "24px" }}
          color="#fff"
          onClick={() => setCardState(CardState.Collapsed)}
        >
          Tap to collapse.
        </Button>
        {announcements.map((announcement) => (
          <AnnouncementCard
            key={announcement.id}
            userId={userId}
            announcements={[announcement]}
            project={project}
          />
        ))}
      </Box>
    );
  }

  const { id, announcedAt, type, content, isTimeSensitive } =
    latestAnnouncement;

  return (
    <>
      <Box
        marginTop={{ base: "10px", sm: "33px" }}
        padding={{ base: "20px 24px", md: "30px 24px" }}
        bg="#232F3D"
        color="#fff"
        borderRadius={{ base: 0, md: "6px" }}
      >
        <Flex w="100%">
          <Image
            borderRadius="full"
            boxSize="30px"
            src={imageUrl}
            w="39px"
            h="39px"
          />

          <Box w="100%" ml="15px">
            <Box
              w="100%"
              display={{ base: "inline-block", md: "flex" }}
              alignItems={{ base: "flex-start" }}
            >
              <Text
                fontWeight={600}
                fontSize={{ base: "16px", lg: "20px" }}
                lineHeight={{ base: "20px", lg: "24px" }}
                letterSpacing={"-0.006em"}
                color="#fff"
                maxW="57%"
              >
                {name}
              </Text>
              <Spacer />
              <Flex alignItems={"center"} mt={{ base: "14px", md: "0" }}>
                <Flex
                  alignItems={"center"}
                  display={{ base: "none", md: "inline-flex" }}
                >
                  <Text
                    color="rgba(255, 255, 255, 0.4)"
                    fontSize={"14px"}
                    fontWeight="400"
                    lineHeight={"24px"}
                    letterSpacing="-0.006em"
                    mr="14px"
                  >
                    {moment(announcedAt).fromNow()}
                  </Text>
                  {isTimeSensitive && (
                    <ImageNext
                      alt=""
                      src={CalendarIcon}
                      width="22px"
                      height="22px"
                    />
                  )}
                </Flex>
                <Box
                  color={feedTypes[type] ? feedTypes[type].color : "#000"}
                  // color={feedTypes[type].color}
                  border={`1px solid ${
                    feedTypes[type] ? feedTypes[type].color : "#000"
                  }`}
                  borderRadius={"5px"}
                  p="3px 10px"
                  boxSizing="border-box"
                  fontWeight={600}
                  fontSize="12px"
                  letterSpacing={"-0.006em"}
                  lineHeight="21px"
                  display={"inline-flex"}
                  alignItems="center"
                  ml={{ base: 0, md: "15px" }}
                >
                  {feedTypes[type] && (
                    <Box display="inline-flex" alignItems="center" mr="5px">
                      <ImageNext
                        alt=""
                        src={feedTypes[type].icon || ""}
                        height="15px"
                        width="15px"
                      />
                    </Box>
                  )}{" "}
                  {type}
                </Box>
                <Flex
                  alignItems={"center"}
                  display={{ base: "inline-flex", md: "none" }}
                >
                  <Text
                    color="rgba(255, 255, 255, 0.4)"
                    fontSize={"14px"}
                    fontWeight="400"
                    lineHeight={"24px"}
                    letterSpacing="-0.006em"
                    mr="14px"
                    ml={{ base: "14px", md: 0 }}
                  >
                    {moment(announcedAt).fromNow()}
                  </Text>
                  {isTimeSensitive && (
                    <ImageNext
                      alt=""
                      src={CalendarIcon}
                      width="22px"
                      height="22px"
                    />
                  )}
                </Flex>
              </Flex>
            </Box>

            <Flex marginTop={"20px"}>
              <Box
                fontWeight={400}
                fontSize={{ base: "13px", lg: "14px" }}
                letterSpacing={"-0.006em"}
                lineHeight={{ base: "24px" }}
                color="rgba(255, 255, 255, 0.6)"
              >
                {announcements.length === 1 ? (
                  content
                ) : (
                  <Text>
                    {announcements.length} updates about an {type}.{" "}
                    <Button
                      variant="link"
                      fontWeight={400}
                      fontSize={{ base: "12px", lg: "14px" }}
                      letterSpacing={"-0.006em"}
                      lineHeight={{ base: "20px", lg: "24px" }}
                      color="rgba(255, 255, 255, 0.6)"
                      onClick={() => setCardState(CardState.Expanded)}
                    >
                      Tap to view.
                    </Button>
                  </Text>
                )}
              </Box>
            </Flex>

            <Flex marginTop={"24px"}>
              <Button
                variant="link"
                color="#fff"
                fontSize={{ base: "0", md: "12px", lg: "14px" }}
                lineHeight={{ base: "20px", lg: "24px" }}
                letterSpacing={"-0.006em"}
                fontWeight="400"
                p="0"
                mr={{ base: "10px", md: "25px" }}
                _hover={{
                  textDecoration: "none",
                  opacity: 0.7,
                }}
                _focus={{
                  outline: "none",
                }}
                _active={{
                  outline: "none",
                }}
                leftIcon={
                  <ImageNext
                    alt=""
                    src={SourceIcon}
                    width="22px"
                    height="22px"
                  />
                }
                padding={2}
                onClick={() => onViewSource()}
              >
                View Source
              </Button>
              <Button
                variant="link"
                color="#fff"
                fontSize={{ base: "0", md: "12px", lg: "14px" }}
                lineHeight={{ base: "20px", lg: "24px" }}
                letterSpacing={"-0.006em"}
                fontWeight="400"
                p="0"
                mr={{ base: "10px", md: "25px" }}
                _hover={{
                  textDecoration: "none",
                  opacity: 0.7,
                }}
                _focus={{
                  outline: "none",
                }}
                _active={{
                  outline: "none",
                }}
                leftIcon={
                  <ImageNext alt="" src={BellIcon} width="20px" height="20px" />
                }
                padding={2}
                onClick={upgrade(() => setShowReminderModal(true))}
              >
                Set Reminder
              </Button>

              <Button
                variant="link"
                color="#fff"
                fontSize={{ base: "0", md: "12px", lg: "14px" }}
                lineHeight={{ base: "20px", lg: "24px" }}
                letterSpacing={"-0.006em"}
                fontWeight="400"
                p="0"
                _hover={{
                  textDecoration: "none",
                  opacity: 0.7,
                }}
                _focus={{
                  outline: "none",
                }}
                _active={{
                  outline: "none",
                }}
                leftIcon={
                  <ImageNext
                    alt=""
                    src={CheckedIcon}
                    width="20px"
                    height="20px"
                  />
                }
                padding={2}
                isLoading={isSubmitting}
                onClick={upgrade(() => markComplete())}
              >
                {announcements.length === 1
                  ? "Mark Complete"
                  : "Mark All Complete"}
              </Button>
            </Flex>
          </Box>
        </Flex>
      </Box>
      {renderModal()}
      {userId && showReminderModal && (
        <SetReminderModal
          userId={userId}
          announcementId={latestAnnouncement.id}
          onClose={() => setShowReminderModal(false)}
        />
      )}
    </>
  );
};

const Content = ({
  user,
  data,
  onlyTimeSensitive,
  smartFilter,
  showCompleted,
  setShowCompleted,
  verificationFilter,
}: {
  user?: User;
  data: AnnouncementsData;
  onlyTimeSensitive: boolean;
  smartFilter: boolean;
  showCompleted: boolean;
  verificationFilter: boolean;
  setShowCompleted: Dispatch<SetStateAction<boolean>>;
}) => {
  const {
    user: { id: userId },
  } = useUserContext();
  const router = useRouter();
  const { analyzing, projects, announcements } = data;

  const filterProjects = useMemo(
    () =>
      projects.filter((project) =>
        verificationFilter
          ? project.verified === "verified" || project.verified === "approved"
          : true
      ),
    [projects, verificationFilter]
  );

  const filteredAnnouncements = useMemo(
    () =>
      announcements
        .filter((a) => !smartFilter || a.isTypeVerified)
        .filter((a) => (onlyTimeSensitive ? a.isTimeSensitive : true))
        .filter((a) => (showCompleted ? true : !a.isCompleted)),
    [announcements, smartFilter, onlyTimeSensitive, showCompleted]
  );

  console.log(filteredAnnouncements);

  const allTypes = Array.from(
    new Set(filteredAnnouncements.map((announcement) => announcement.type))
  );
  const groups = useMemo<
    Array<{
      twitterUsername: string;
      type: string;
      announcedAt?: string;
      min: number;
      max: number;
    }>
  >(() => {
    const groups = [] as Array<{
      twitterUsername: string;
      type: string;
      announcedAt?: string;
      min: number;
      max: number;
    }>;
    let countObj: Record<string, number> = {};

    const announcements = [...filteredAnnouncements];
    announcements.forEach(({ twitterUsername, type, announcedAt }) => {
      if (
        type &&
        !groups.some((group) => {
          const name = (twitterUsername + type).toLowerCase();
          const dateDiff = getDateDifference(Date.now(), announcedAt);
          const res =
            group.twitterUsername === twitterUsername &&
            group.type === type &&
            dateDiff <=
              (!countObj[name]
                ? config.dateIntervalForMergingProjects - 1
                : countObj[name]);

          return res;
        })
      ) {
        const name = (twitterUsername + type).toLowerCase();
        const countRes = countObj[name]
          ? countObj[name] + config.dateIntervalForMergingProjects
          : config.dateIntervalForMergingProjects - 1;
        countObj[name] = countRes;

        const minVal = countObj[name]
          ? countObj[name] - config.dateIntervalForMergingProjects + 1
          : 0;

        groups.push({
          twitterUsername,
          type,
          announcedAt,
          max: countObj[name],
          min: minVal,
        });
      }
    });

    return groups;
  }, [filteredAnnouncements]);

  if (filteredAnnouncements.length === 0 && !analyzing) {
    return (
      <>
        <Box
          bg="#232F3D"
          borderRadius={"6px"}
          padding={"30px 50px"}
          my={{ base: "10px", md: "35px" }}
        >
          <Text
            fontSize={{ base: "16px", lg: "20px" }}
            lineHeight={"24px"}
            fontWeight="600"
            letterSpacing={"-0.006em"}
            color="#fff"
            mb="22px"
            display="flex"
            alignItems="center"
          >
            No Recent Announcements{" "}
            <Flex ml="6px">
              <ImageNext alt="" src={UserIcon} width="21px" height="21px" />
            </Flex>
          </Text>

          <Text
            fontSize={{ base: "12px", lg: "14px" }}
            lineHeight={"24px"}
            fontWeight="400"
            letterSpacing={"-0.006em"}
            color="rgba(255, 255, 255, 0.6)"
          >
            Scottie couldn’t find any important announcements in the past 7 days
            for NFTs you own.
          </Text>
        </Box>

        <Box bg="#232F3D" borderRadius={"6px"} padding={"30px 50px"}>
          <Text
            fontSize={{ base: "16px", lg: "20px" }}
            lineHeight={"24px"}
            fontWeight="600"
            letterSpacing={"-0.006em"}
            color="#fff"
            mb="22px"
            display="flex"
            alignItems="center"
          >
            Want to track more projects?{" "}
            <Flex ml="6px">
              <ImageNext alt="" src={UserIcon} width="21px" height="21px" />
            </Flex>
          </Text>

          <Text
            fontSize={{ base: "12px", lg: "14px" }}
            lineHeight={"24px"}
            fontWeight="400"
            letterSpacing={"-0.006em"}
            color="rgba(255, 255, 255, 0.6)"
            mb="10px"
          >
            Try out the following features:
          </Text>

          <Text
            fontSize={{ base: "12px", lg: "14px" }}
            lineHeight={"24px"}
            fontWeight="400"
            letterSpacing={"-0.006em"}
            color="rgba(255, 255, 255, 0.6)"
          >
            <Link href="#" color="#439AFF" textDecoration={"none"}>
              Track Additional Projects
            </Link>
            , even if you don’t own them or if they are pre-launch.
          </Text>

          <Text
            fontSize={{ base: "12px", lg: "14px" }}
            lineHeight={"24px"}
            fontWeight="400"
            letterSpacing={"-0.006em"}
            color="rgba(255, 255, 255, 0.6)"
          >
            <Link href="#" color="#439AFF" textDecoration={"none"}>
              Add Additional Wallets
            </Link>{" "}
            if you have NFTs spread across multiple wallets.
          </Text>
        </Box>

        {announcements.length === 0 ? null : (
          <Button variant="link" onClick={() => setShowCompleted(true)}>
            <Box borderWidth="1px" marginTop={8} padding={4}>
              <Text fontSize="sm">
                View Older Announcements ({announcements.length} total)
              </Text>
            </Box>
          </Button>
        )}
      </>
    );
  }

  return (
    <>
      {groups.map(({ twitterUsername, type, announcedAt, min, max }) => {
        const project = filterProjects.find(
          (p) => p.twitterUsername === twitterUsername
        );

        if (!project) {
          return null;
        }

        const projectAnnouncements = filteredAnnouncements.filter((a) => {
          return (
            a.twitterUsername === project.twitterUsername &&
            a.type === type &&
            getDateDifference(Date.now(), a.announcedAt) >= min &&
            getDateDifference(Date.now(), a.announcedAt) <= max
          );
        });

        if (projectAnnouncements.length === 0) {
          return null;
        }

        return (
          <AnnouncementCard
            key={`${twitterUsername}-${type}-${min}-${max}`}
            userId={userId}
            announcements={projectAnnouncements}
            project={project}
          />
        );
      })}
    </>
  );
};

export default Content;
