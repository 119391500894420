import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { formatDate } from "utils/formatDate";

export type DashboardTable = {
  slug: string;
  twitterUsername:
    | { status: "success"; value: string }
    | { status: "error"; value: string }
    | { status: "pending" };
  lastChecked?: Date | null;
  lastMatched?: {
    id: number;
    content: string | null;
    tweetId: string;
    announcedAt: Date;
  };
};

export type AnalysisResponseData = {
  data: DashboardTable[];
  total: number;
  completed: number;
};

interface IProps {
  analysisData?: AnalysisResponseData;
  onClose: Function;
  loading: string;
}

const fields: string[] = ["name", "handle", "last queried", "last matched"];

const DashboardAnalysis = ({ analysisData, onClose, loading }: IProps) => {
  const [analysis, setAnalysis] = useState<DashboardTable[]>([]);

  useEffect(() => {
    if (analysisData?.data) setAnalysis(analysisData.data);
  }, [analysisData]);

  if (!analysisData)
    return (
      <Box
        alignItems={"center"}
        border="1px solid #17202A"
        p="30px 30px"
        ml="auto"
        mt={"20px"}
        bg="#232F3D"
        boxSizing="border-box"
        borderRadius={"6px"}
        mx={{ base: "15px", sm: "0" }}
      >
        <Text>No data.</Text>
      </Box>
    );

  const { data, completed, total } = analysisData;

  if (!data || data.length === 0) return <div>No data</div>;

  const handleChangeAnalysisData = (text: string) => {
    if (!text) return setAnalysis(data);
    const filteredData = data.filter((item: DashboardTable) =>
      item.slug.startsWith(text)
    );
    setAnalysis(filteredData);
  };

  return (
    <Box
      alignItems={"center"}
      border="1px solid #17202A"
      p="30px 30px"
      ml="auto"
      mt={"20px"}
      bg="#232F3D"
      boxSizing="border-box"
      borderRadius={"6px"}
      mx={{ base: "15px", sm: "0" }}
    >
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        boxSizing="border-box"
      >
        <Text
          fontSize={"16px"}
          lineHeight="24px"
          fontWeight={"400"}
          letterSpacing="-0.006em"
          color="#9AA6AC"
          mr="10px"
          fontFamily={"Space Mono"}
        >
          {loading !== "completed"
            ? `${completed}/${total} Complete`
            : `Analysis Complete. ${total - completed} projects ignored.`}
        </Text>

        <Text
          fontSize={"16px"}
          lineHeight="24px"
          fontWeight={"400"}
          letterSpacing="0.07em"
          mr="10px"
          fontFamily={"Space Mono"}
          color={"rgba(255, 255, 255, 0.5)"}
          cursor={"pointer"}
          onClick={() => onClose()}
        >
          Close
        </Text>
      </Box>

      <Box mt={"30px"}>
        <InputGroup
          border="1px solid #0E141C"
          borderRadius={"10px"}
          display={"flex"}
          alignItems={"center"}
        >
          <InputLeftElement
            pointerEvents="none"
            height={"100%"}
            // eslint-disable-next-line react/no-children-prop
            children={<SearchIcon color="white" />}
          />
          <Input
            size="lg"
            fontSize={"16px"}
            color={"#9AA6AC"}
            background={"#0E141C"}
            placeholder="Filter by name"
            onChange={(e) => handleChangeAnalysisData(e.target.value)}
          />
        </InputGroup>
      </Box>

      <Box
        overflowY={"scroll"}
        overflowX={"auto"}
        maxHeight="300px"
        mt={"30px"}
        css={{
          "&::-webkit-scrollbar": {
            marginLeft: "30px",
            width: "7px",
            backgroundColor: "#f5f5f5",
          },
          "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            backgroundColor: "#f5f5f5",
          },
          "&::-webkit-scrollbar-thumb": {
            borderRadius: "24px",
            boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
            backgroundColor: "#555",
          },
        }}
      >
        <TableContainer color={"#9AA6AC"}>
          <Table>
            <Thead>
              <Tr>
                {fields.map((field) => (
                  <Th
                    color={"#ffffffb3"}
                    fontSize={"14px"}
                    fontWeight={"700"}
                    textTransform={"capitalize"}
                    key={field}
                  >
                    {field}
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {analysis?.map((item: DashboardTable) => (
                <Tr key={item.slug}>
                  <Td
                    fontSize={"14px"}
                    color={"#FFFFFFB2"}
                    // maxWidth={"300px"}
                    // wordBreak={"break-all"}
                  >
                    {item?.slug}
                  </Td>
                  <Td fontSize={"14px"} color={"#FFFFFFB2"}>
                    {item?.twitterUsername?.status === "success"
                      ? item?.twitterUsername.value
                      : "None found"}
                  </Td>
                  <Td fontSize={"14px"} color={"#FFFFFFB2"}>
                    {item?.lastChecked ? formatDate(item?.lastChecked) : ""}
                  </Td>
                  <Td fontSize={"14px"} color={"#FFFFFFB2"}>
                    {item?.lastMatched
                      ? formatDate(item?.lastMatched?.announcedAt)
                      : ""}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default DashboardAnalysis;
