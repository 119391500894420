import { useState } from "react";
import {
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
  Divider,
  Flex,
  Box,
  Text,
  Container,
  Button,
  Tabs,
  Tab,
  TabList,
} from "@chakra-ui/react";
import {
  ChevronDownIcon,
  HamburgerIcon,
  AddIcon,
  ExternalLinkIcon,
  RepeatIcon,
  EditIcon,
} from "@chakra-ui/icons";
import Image from "next/image";
import NextLink from "next/link";
import useAPI, { get } from "@lib/hooks/api";
import { useUserContext } from "@lib/hooks/user";
import { useWindowSize } from "@lib/hooks/useWindowSize";
import { useCallback } from "react";
import { useRouter } from "next/router";
import { useUpgrade } from "./upgrade";

import LogoSvg from "public/images/logo.svg";
import AvatarSvg from "public/images/default-avt.svg";
import TabCalendarSvg from "public/images/tab-calendar.svg";
import TabCalendarActiveSvg from "public/images/tab-calendar-active.svg";
import TabBellSvg from "public/images/tab-bell.svg";
import TabBellActiveSvg from "public/images/tab-bell-active.svg";
import TabSettingsSvg from "public/images/tab-settings.svg";
import { useDynamicContext } from "@dynamic-labs/sdk-react";
import { useSWRConfig } from "swr";

const Navigation = ({ upgrade }: { upgrade: any }) => {
  const [width] = useWindowSize();
  const { user } = useUserContext();
  const wallet = user?.primaryWallet;
  const router = useRouter();
  const [signupPage, setSignupPage] = useState(
    router.pathname === "/signup" ? true : false
  );
  const { mutate } = useSWRConfig();

  console.log("wallet", wallet);

  const { handleLogOut } = useDynamicContext();

  const logOut = async () => {
    try {
      await get("/api/auth/logout");
      handleLogOut();
      await mutate("/api/auth");
      router.reload();
    } catch (err) {
      console.log(err);
    }
  };

  const pathname = router.pathname;

  if (width > 768) {
    return (
      <Flex
        h={16}
        alignItems={"center"}
        justifyContent={"flex-start"}
        bg="#17202A"
        color="#fff"
        p={{ base: "10px 24px", lg: "18px 80px" }}
        height="auto"
        borderBottom="1px solid rgba(255, 255, 255, 0.2)"
      >
        <Box mr={{ base: "auto", md: "0" }}>
          <NextLink href="/">
            <a style={{ width: "200px", display: "inline-flex" }}>
              <Image src={LogoSvg} alt="Logo" width={"200px"} />
            </a>
          </NextLink>
        </Box>

        {!signupPage && (
          <Flex
            alignItems={"space-between"}
            mx="auto"
            display={{ base: "none", md: "flex" }}
          >
            {/* <Box mr="42px">
              <Button
                variant="link"
                onClick={upgrade(() => router.push("/alpha"))}
                fontSize={{ base: "14px", lg: "19px" }}
                lineHeight="28px"
                fontWeight="600"
                color={pathname === "/alpha" ? "#F4B453" : "#fff"}
                _hover={{
                  color: "#F4B453",
                  textDecoration: "none",
                }}
                _focus={{
                  outline: "none",
                }}
              >
                Alpha
              </Button>
            </Box> */}
            <Box mr="42px">
              <Button
                variant="link"
                onClick={() => router.push("/")}
                fontSize={{ base: "13px" }}
                lineHeight="28px"
                fontWeight="600"
                fontFamily={"Space Mono"}
                color={pathname === "/" ? "#fff" : "#FFFFFF80"}
                background={
                  pathname === "/" ? "rgba(255, 255, 255, 0.1)" : "transparent"
                }
                padding="7px"
                _hover={{
                  color: "#fff",
                  textDecoration: "none",
                }}
                _focus={{
                  outline: "none",
                  color: "#fff",
                }}
              >
                Updates
              </Button>
            </Box>
            <Box mr="42px">
              <Button
                variant="link"
                onClick={upgrade(() => router.push("/reminders"))}
                fontSize={{ base: "13px" }}
                lineHeight="28px"
                fontWeight="600"
                fontFamily={"Space Mono"}
                color={pathname === "/reminders" ? "#fff" : "#FFFFFF80"}
                background={
                  pathname === "/reminders"
                    ? "rgba(255, 255, 255, 0.1)"
                    : "transparent"
                }
                padding="7px"
                _hover={{
                  color: "#fff",
                  textDecoration: "none",
                }}
                _focus={{
                  outline: "none",
                  color: "#fff",
                }}
              >
                Reminders
              </Button>
            </Box>
            <Box>
              <Button
                variant="link"
                onClick={upgrade(() => router.push("/settings"))}
                fontSize={{ base: "13px" }}
                lineHeight="28px"
                fontWeight="600"
                fontFamily={"Space Mono"}
                color={pathname === "/settings" ? "#fff" : "#FFFFFF80"}
                background={
                  pathname === "/settings"
                    ? "rgba(255, 255, 255, 0.1)"
                    : "transparent"
                }
                padding="7px"
                _hover={{
                  color: "#fff",
                  textDecoration: "none",
                }}
                _focus={{
                  outline: "none",
                  color: "#fff",
                }}
              >
                Settings
              </Button>
            </Box>
          </Flex>
        )}

        {!signupPage && (
          <Menu>
            {wallet ? (
              <MenuButton
                as={Button}
                rightIcon={<ChevronDownIcon />}
                onClick={logOut}
                bg="#232F3D"
                fontSize={"14px"}
                lineHeight="18px"
                letterSpacing={"0.02em"}
                color="#fff"
                display={{ base: "none", md: "inline-flex" }}
                fontFamily={"Space Mono"}
                alignItems="center"
                shadow="0px 3.06709px 6.13417px -1.53354px rgba(16, 24, 40, 0.1), 0px 1.53354px 3.06709px -1.53354px rgba(16, 24, 40, 0.06)"
                _hover={{
                  bg: "#232F3D",
                  opacity: 0.7,
                }}
                _focus={{
                  bg: "#232F3D",
                  opacity: 0.7,
                }}
                leftIcon={
                  <Image alt="" src={AvatarSvg} width="18px" height={"18px"} />
                }
              >
                {wallet.substr(0, 10)}...
              </MenuButton>
            ) : (
              <MenuButton></MenuButton>
            )}
          </Menu>
        )}
      </Flex>
    );
  } else {
    return (
      <Box
        px={"30px"}
        py="10px"
        position="fixed"
        bottom={0}
        left={0}
        right={0}
        // bg="linear-gradient(180deg, rgba(23, 32, 42, 0) 0%, #17202A 66.99%, #17202A 105.91%)"
        bg="#17202A"
        zIndex={1000}
      >
        <Tabs isFitted variant="unstyled">
          <TabList>
            <Tab
              onClick={upgrade(() => router.push("/reminders"))}
              _hover={{
                opacity: 0.7,
              }}
              _focus={{
                outline: "none",
              }}
            >
              <Image
                alt=""
                src={
                  pathname === "/reminders"
                    ? TabCalendarActiveSvg
                    : TabCalendarSvg
                }
                width="40px"
                height="40px"
              />
            </Tab>
            <Tab
              onClick={() => router.push("/")}
              _hover={{
                opacity: 0.7,
              }}
              _focus={{
                outline: "none",
              }}
            >
              <Image
                alt=""
                src={pathname === "/" ? TabBellActiveSvg : TabBellSvg}
                width="40px"
                height="40px"
              />
            </Tab>
            <Tab
              onClick={upgrade(() => router.push("/settings"))}
              _hover={{
                opacity: 0.7,
              }}
              _focus={{
                outline: "none",
              }}
            >
              <Image alt="" src={TabSettingsSvg} width="40px" height="40px" />
            </Tab>
          </TabList>
        </Tabs>
      </Box>
    );
  }
};

const Page = ({ children }: { children: React.ReactChild }) => {
  const { upgrade, renderModal } = useUpgrade();
  const router = useRouter();

  return (
    <Box bg="#17202A">
      <Navigation upgrade={upgrade} />
      {renderModal()}
      <Box
        py={{ base: "0", lg: "72px" }}
        minH={{
          base: `calc(100vh - ${router.pathname === "/signup" ? 0 : 75}px)`,
          lg: "calc(100vh - 78px)",
        }}
      >
        <Container
          maxW={{
            base: "100%",
            md: `${router.pathname === "/" ? "1080" : "722"}px`,
          }}
          px={{ base: "0", sm: "26px", md: "0" }}
        >
          {children}
        </Container>
      </Box>
    </Box>
  );
};

export default Page;
